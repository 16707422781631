<template>
<div class="page_wrap">
  <div class="preloader" v-if="this.preloader">
    <div class="preloader_back">&nbsp;</div>
    <div class="preloader_text">
      {{this.$t('Подождите, пожалуйста')}} ...
      <br>
      <br>
      <img src="@/assets/loader.svg" alt="...">
    </div>
  </div>
  <div v-if="page.owner_status">
    <div v-if="!this.isIos() && !this.isAndroid()" class="qrDiv">
      <h1>{{this.$t('Отсканируйте QR-код')}}</h1>
      <qr-code :text="this.qrHref" :size="256"></qr-code>
      <p>{{this.$t('для того чтобы завершить подписку')}}</p>
    </div>
    <div v-if="this.isIos() || this.isAndroid()">
      <div class="page_middle">
        <img :src="this.page.top_img" class="page_top_img" v-if="this.page.top_img">
        <div class="page_middle_inner">
          <h1>{{this.page.h1}}</h1>
          <br>
          <div class="page_text" v-html="this.page.text"></div>
          <br>
          <div v-if="this.page.categories.length">
            <p><strong>{{ this.$t('Выберите категории для подписки') }}</strong></p>
            <CInputCheckbox
                v-for="category in this.page.categories"
                :key="category.id"
                :label="category.title"
                :value="category.id"
                :checked="page.guest_categories.indexOf(category.id) > -1"
                @update:checked="(val) => checked(val, category.id)"
                :custom="true"
                color="info"
            />
          </div>
        </div>
      </div>
      <div class="page_footer">
        <a :href="this.page.link" class="page_link" v-if="this.page.link && (!this.page.calendar.categories_enabled || this.page.guest_categories.length)">
          <CButton size="sm" color="info" class="page_button page_button_sub" @click="subscribe()">{{this.page.button_text}}</CButton>
        </a>
        <CButton size="sm" color="info" class="page_button page_button_sub" @click="subscribe()" v-if="(this.page.already && this.page.calendar.categories_enabled) || (!page.already && !this.isIos() && this.page.hasToken)">{{this.page.button_text}}</CButton>
        <CButton size="sm" color="info" class="page_button page_button_unsub" @click="unsubscribe()" v-if="page.already">{{$t('Отписаться')}}</CButton>
      </div>
    </div>
    <div class="scripts" hidden v-html="this.page.scripts"></div>
    <notifications group="calendar" />
  </div>
  <div v-if="!page.owner_status">
    <br><br>
    Страница деактивирована.
    <br><br>
    Если вы владелец этого аккаунта, свяжитесь с нами
    <a href="https://evli.online/" target="_blank">https://evli.online/</a>
  </div>
</div>
</template>

<script>
import axios from '@/modules/axios'

export default {
  name: 'CalendarSign',
  data() {
    return {
      code: '',
      preloader: false,
      qrHref: document.location.href,
      success_link: '/success.html',
      page: {
        id: 0,
        top_img: '',
        h1: '',
        text: '',
        button_text: 'Подписаться',
        calendar_id: 0,
        calendar: {
          code: '',
          categories_enabled: false,
          success_link: ''
        },
        link: '',
        originalLink: '',
        already: false,
        categories: [],
        guest_categories: [],
        owner_status: 1,
        hasToken: false
      },
      google: {
        clientId: '733572515002-eact72j50tlkki4oe73sutdc7vhpj9ak.apps.googleusercontent.com',
        apiKey: 'AIzaSyDHCLV1XSiJIBa-wvcmSw3J5OmETlmSFAI',
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
        scope: 'https://www.googleapis.com/auth/calendar',
      }
    }
  },
  created() {
    this.checkCode()
    this.getPage()
  },
  methods: {
    checkCode() {
      if(typeof location.pathname === 'undefined'){
        window.location.href = "/landing.html";
      }
      let pathname = location.pathname.split('?')[0]
      pathname = pathname
          .replace('/', '')
          .replace('/', '')
          .replace('#', '')
          .replace('&redirect=true', '')
          .replace('redirect=true', '')
          .replace('?r=t', '')
      if(pathname === ''){
        window.location.href = "/landing.html";
      }
      this.code = pathname
    },
    getPage () {
      if(!this.code) return

      const url = new URL(location.href)
      const uuid = url.searchParams.get('uuid')
      const user_id = url.searchParams.get('user_id')
      if(uuid) {
        localStorage.setItem('uuid', uuid)
      }

      axios.get('/page/info', {
        params: {
          code: this.code,
          uuid: localStorage.getItem('uuid'),
          clientUserId: user_id,
          deviceType: this.isIos() ? 'apple' : (this.isAndroid() ? 'google' : 'pc'),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }).then((res) => {
        this.page = res.data.data
        this.success_link = this.page.calendar.success_link || this.success_link
        this.page.originalLink = this.page.link
        localStorage.setItem('uuid', res.data.data.uuid)
        this.state()
        localStorage.setItem('link', this.page.link)
        if(!this.page.already && this.isAndroid() && !this.page.hasToken){
          this.page.link = '/notice.html'
        }
        this.scripts()

        setTimeout(() => {
          if(document.location.href.indexOf('redirect=true') > 0 || document.location.href.indexOf('r=t') > 0){
            const interval = setInterval(() => {
              if(document.getElementsByClassName('page_button_sub').length > 0
                  || document.getElementsByClassName('page_button_unsub').length > 0){
                clearInterval(interval)
                if(document.getElementsByClassName('page_link').length > 0){
                  document.getElementsByClassName('page_link')[0].click()
                  if(this.isIos()){
                    document.getElementsByClassName('page_button_sub')[0].click()
                  }
                } else if(document.getElementsByClassName('page_button_sub').length > 0
                    && this.page.guest_categories.length === 0){
                  document.getElementsByClassName('page_button_sub')[0].click()
                }
              }
            }, 100)
          } else if(this.page.domain && this.page.domain.length > 0) {
            this.qrHref = this.qrHref.replace('#/', '&redirect=true')
          }
        }, 1);

      }).catch((error) => {
        console.log(error)
      })
    },
    unsubscribe() {
      this.preloader = true
      axios.post('/guest/unsubscribe', {
        calendar_id: this.page.calendar_id,
        uuid: localStorage.getItem('uuid'),
      }).then(() => {
        if(document.location.href.indexOf('redirect=true') > 0 || document.location.href.indexOf('r=t') > 0){
          const redirect = location.href
              .replace('&redirect=true', '')
              .replace('redirect=true', '')
              .replace('r=t', '')
          window.location.href = redirect;
        } else {
          this.getPage()
          this.preloader = false
        }
      }).catch((error) => {
        this.preloader = false
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    subscribe() {
      if(!this.page.already && (this.isIos() || this.page.hasToken)){
        this.preloader = true
        axios.post('/guest/create-calendar', {
          calendarCode: this.page.calendar.code,
          guestUuid: localStorage.getItem('uuid'),
          device: this.isIos() ? 'apple' : 'google',
          categoriesIds: this.page.guest_categories,
          pageId: this.page.id
        }).then(() => {
          if(this.isIos()){
            setTimeout(() => {
              window.location.href = this.success_link
            }, 2000);
          } else {
            window.location.href = this.success_link
          }
        }).catch((error) => {
          this.preloader = false
          this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
        })
      } else if(this.page.categories && this.page.already){
        this.preloader = true
        axios.post('/guest/update-categories', {
          calendar_id: this.page.calendar_id,
          guestUuid: localStorage.getItem('uuid'),
          device: this.isIos() ? 'apple' : 'google',
          categoriesIds: this.page.guest_categories
        }).then(() => {
          setTimeout(() => {
            window.location.href = this.success_link
          }, 1);
        }).catch((error) => {
          this.preloader = false
          this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
        })
      }
    },
    state() {
      if(!this.isAndroid() || this.page.already || this.page.originalLink.indexOf('&state=') === -1) return
      let parts = this.page.originalLink.split('&state=')
      parts = parts[1].split('&')
      const search = parts[0]
      let state = JSON.parse(decodeURIComponent(decodeURIComponent(search)))
      state['cats'] = this.page.guest_categories
      state = encodeURIComponent(encodeURIComponent(JSON.stringify(state)))
      this.page.link = this.page.originalLink.replace(search, state)
    },
    checked(checked, categoryId){
      if(checked && this.page.guest_categories.indexOf(categoryId) === -1){
        this.page.guest_categories.push(categoryId)
      } else if(!checked && this.page.guest_categories.indexOf(categoryId) !== -1){
        this.page.guest_categories.splice(this.page.guest_categories.indexOf(categoryId), 1);
      }
      this.state()
    },
    isIos() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    isAndroid() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    },
    scripts() {
      setTimeout(() => {
        var scripts = Array.from(document.getElementsByClassName('scripts')[0].getElementsByTagName("script"));
        for (var i = 0; i < scripts.length; i++) {
          if (scripts[i].src !== "") {
            var tag = document.createElement("script");
            tag.src = scripts[i].src;
            document.getElementsByTagName("head")[0].appendChild(tag);
          }
          else {
            eval(scripts[i].innerHTML);
          }
        }
      }, 1);
    }
  }
}
</script>
<style lang="scss">
* { padding: 0; margin: 0; }
html, body, .page_wrap {
  min-height: 100% !important;
  position: relative;
  background: white;
}
.page_wrap {
  background: white;
  max-width: 100%;
  width: 60em;
  margin: 0 auto;
  min-height: 100%;
  text-align: center;
  position: relative;

}
.page_top_img {
  width: 100%
}
.page_middle {
  width: 100%;
  font-size: 1.1em;
  position: fixed;
  height: 85%;
  overflow: auto;
}
.page_middle_inner {
  padding: 1.2em;
}
.page_footer {
  position: fixed;
  background: white;
  text-align: center;
  bottom: 0;
  min-width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}
.page_button {
  font-size: 1.5em;
  padding: 0.5em;
  margin: 1em;
}
.col-form-label {
  text-align: left;
  margin-top: -0.3em;
}
.custom-checkbox {
  margin: 1em 0;
  font-size: 1.1em;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #39f;
  background-color: #39f;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
h1 {
  font-size: 1.1em;
}
.page_text {
  white-space: pre-wrap;
  h1 {
    font-size: 2.1875rem;
  }
  blockquote {
    padding: 0.3em 1em;
    border-left: 0.3em solid lightgrey;
    margin-inline-start: 0;
    margin-inline-end: 0;
    display: inline;
  }
  .ql-syntax {
    background: black;
    padding: 0.5em 1em;
    color: white;
  }
  a {
    text-decoration: underline;
  }
}
.qrDiv {
  text-align: center;
  padding-top: 5em;
  div {
    display: inline-block;
  }
  h1 {
    font-size: 1.5em;
    margin-bottom: 3em;
  }
  p {
    font-size: 1.5em;
    margin-top: 3em;
  }
}
.preloader {
  .preloader_back {
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.7;
  }
  .preloader_text {
    position: fixed;
    left: 0;
    top: 40%;
    z-index: 3;
    width: 100%;
    font-size: 2em;
    img {
      width: 3em;
    }
  }
}
</style>
